import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, of, tap, switchMap, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environments';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private apiUrl = `${environment.apiUrl}/auth/login`;
    private apiCsrf = "https://educsteam.com/api/sanctum/csrf-cookie";

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) { }

    // Método para obtener la cookie CSRF
    getCsrfToken(): Observable<any> {
        return this.http.get(this.apiCsrf, { withCredentials: true }).pipe(
            tap(() => console.log('CSRF token obtained')),
            catchError(error => {
                console.error('Error obtaining CSRF token:', error);
                return of(false);
            })
        );
    }   

    // Método para autenticarse
    authenticate(email: string, password: string): Observable<any> {
        return this.getCsrfToken().pipe(
            switchMap(() => {
                const headers = new HttpHeaders({
                    'X-XSRF-TOKEN': 'null'  // Incluye el token en la cabecera si existe
                });
                return this.http.post(this.apiUrl, { email, password }, { headers, withCredentials: true });
            }),
            tap((response: any) => {
                localStorage.setItem('authToken', response.access_token);
                localStorage.setItem('userData', JSON.stringify(response.user));
            }),
            catchError(error => {
                console.error('Error during authentication:', error);
                return of(false);
            })
        );
    }

    getUserData() {
        const userData = localStorage.getItem('userData');
        return userData ? JSON.parse(userData) : null;
    }

    logout() {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userData');
    }

    isAuthenticated(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            return !!localStorage.getItem('authToken');
        }
        return false;
    }
}
